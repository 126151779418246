import { logoutAdmin } from '../stores/admin';

export const isAdminRoute = ( url: string ) => {
  return url.indexOf( '/admin' ) !== -1 || url.indexOf( '/forward' ) !== -1;
};

export const logoutInstance = () => {
  logoutAdmin();
};

export const getBaseUrl = ( path?: string ) => {
  if ( !path ) path = '';
  path = path.startsWith( '/' ) ? path : '/' + path;
  // eslint-disable-next-line no-undef
  return (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL) + path;
};

export const getCameraBasicAuthToken = () => {
  return btoa( getCameraBasicAuthCred() );
};

export const getCameraBasicAuthCred = (): string => {
  // eslint-disable-next-line no-undef
  return process.env.REACT_APP_CAMERA_CRED || '';
};

export const getApiUrl = ( path?: string ) => {
  if ( !path ) path = '';
  path = path.startsWith( '/' ) ? path : '/' + path;
  return getBaseUrl( '/api' + path );
};

export const getImageUrl = ( path?: string ) => {
  return getBaseUrl( path );
};
