import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API } from './global';
import { getAdminToken } from '../stores/admin';
import { IApiRequestGet, IApiRequestPost } from '../types/api';
import { logoutInstance } from './helpers';
import { showAlert } from '../components/Alert/store';
import { hidePreloader } from '../stores/preloader';
import { $selectedCompanyId } from '../stores/companies';

export const Api = axios.create( {
  baseURL: API.url,
  timeout: API.timeout,
} );

const store: any = {
  selectedCompanyId: ''
};

$selectedCompanyId.watch( s => {
  store.selectedCompanyId = s;
} );

export const getHeaders = ( token: string, data: any ) => {
  const headers: any = {};

  if ( !( data instanceof FormData ) ) {
    // @ts-ignore
    headers[ 'Content-Type' ] = 'application/json';
  }

  if ( token ) {
    // @ts-ignore
    headers.Authorization = `Bearer ${ token }`;
  }

  if ( store.selectedCompanyId ) {
    headers.CompanyId = store.selectedCompanyId;
  }

  return headers;
};

// @ts-ignore
Api.interceptors.request.use( ( config: AxiosRequestConfig ) => {
  let token = '';
  // if ( isAdmin() ) {
  token = getAdminToken();
  // } else {
  //   token = getUserToken();
  // }

  config.headers = { ...config.headers, ...getHeaders( token, config.data ) };

  return config;
} );

const successHandler = ( response: AxiosResponse ) => {
  hidePreloader();

  if ( response.status === 401 ) {
    logoutInstance();
  }

  if ( response.status === 400 ) {
    showAlert( {
      type: 'danger',
      message: response.data.message,
    } );
  }

  return response.data;
};

const errorHandler = ( error: AxiosError ) => {
  hidePreloader();

  //@ts-ignore
  if ( error.response.status === 401 ) {
    // @ts-ignore
    logoutInstance();
  }

  if ( error.response?.status === 400 ) {
    showAlert( {
      type: 'danger',
      // @ts-ignore
      message: error.response?.data?.message,
    } );
  }

  if ( error.response?.status === 500 ) {
    showAlert( {
      type: 'danger',
      message: 'Что то пошло не так...',
    } );
  }

  if ( error.response?.status === 403 ) {
    showAlert( {
      type: 'danger',
      // @ts-ignore
      message: 'Доступ к ресурсу запрещен!',
    } );
  }

  throw error;
};

export async function apiPost<T>( { url, postData }: IApiRequestPost ): Promise<T> {
  return await Api.post( url, postData )
    .then( successHandler )
    .catch( errorHandler );
}

export async function apiGet<T>( { url }: IApiRequestGet ): Promise<T> {
  return Api.get( url )
    .then( successHandler )
    .catch( errorHandler );
}

export async function apiPatch<T>(
  {
    url,
    postData,
  }: IApiRequestPost ): Promise<T> {

  return await Api.patch( url, postData )
    .then( successHandler )
    .catch( errorHandler );
}

export async function apiDelete<T>(
  {
    url,
    postData,
    headerList = {},
  }: IApiRequestPost ): Promise<T> {

  return await Api.delete( url, {
    data: postData,
    headers: headerList,
  } )
    .then( successHandler )
    .catch( errorHandler );
}
