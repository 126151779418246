import { createEvent, createStore } from 'effector';
import { IPermission, IPermissionsType } from '../types/permissions';
import { apiGet } from '../api';
import { updateAdmin } from './admin';

export interface IPermissions extends IPermissionsType {
  get: ( group: string, action: string ) => boolean;
  getIn: ( permission: string | string[] ) => boolean; // `group:access` format
  updatePermissions: ( permissions: any ) => void;
}

export const updateUserPermissions = createEvent<IPermission[]>();

export const $userPermissions = createStore<IPermissions | null>( null )
  .on( updateUserPermissions, ( state, payload ) =>
    // @ts-ignore
    new UserPermissions( payload )
  );

updateAdmin.watch( ( state ) => {
  updateUserPermissions( state.permissions );
} );

function UserPermissions( permissions: IPermission[] ) {
  let permissionStore: any = {};
  // @ts-ignore
  this.updatePermissions = ( permissions: any ) => {
    permissions?.forEach( ( s: any ) => {
      if ( permissionStore[ s.group ] === undefined ) {
        permissionStore[ s.group ] = {};
      }
      permissionStore[ s.group ][ s.action ] = true;
    } );
  };

  // @ts-ignore
  this.updatePermissions( permissions );

  // @ts-ignore
  this.get = ( group: string, action: string ) => {
    if ( !group && !action ) return true;
    if ( !permissionStore[ group ] ) return false;
    return permissionStore[ group ][ action ];
  };
  // @ts-ignore
  this.getIn = ( permission: string | string[] ) => {
    let accessStatus = false;
    if ( typeof permission === 'string' ) {
      permission = [ permission ];
    }
    permission?.forEach( ( access ) => {
      const [ group, action ] = access.split( ':' );
      // @ts-ignore
      if ( this.get( group, action ) ) {
        accessStatus = true;
      }
    } );

    return accessStatus;
  };
}

// Permissions all
export const getPermissions = async ( page?: string ) => {

  const response = await apiGet<IPermissions>( {
    url: `/admin/permissions?page=${ page }`,
  } );

  updatePermissions( response.items );

  return response;
};

export const updatePermissions = createEvent<IPermission[]>();

export const $permissions = createStore<IPermission[]>( [] )
  .on( updatePermissions, ( state, payload ) => payload );

