import { getImageUrl } from '../api/helpers';

export const imageResource = ( link?: string, width?: number, height?: number ) => {
  if ( !link ) link = 'placeholder.png';

  if ( !link ) link = '';
  link = link.startsWith( '/' ) ? link : '/' + link;

  let _link = link.split( '.' );
  let ext = _link.pop();

  if ( width || height ) {
    link = _link.join( '.' ) + `-${ width }x${ height }.${ ext }`;
    link = 'storage/cache' + link;
  } else {
    link = _link.join( '.' ) + `.${ ext }`;
  }

  return getImageUrl( link );
};
