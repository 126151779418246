import { apiGet, apiPost } from '../api';
import { FilterParams } from '../util/filter_util';
import { ICompanies, ICompany, IPinCode } from '../types/companies';
import { IError } from '../types/api';
import { createEvent, createStore } from 'effector';

export const GENERAL_COMPANY_ID = '1';

export const selectCompanyId = createEvent<string | null>();
export const $selectedCompanyId = createStore<string | null>( localStorage.getItem( 'selecte_company_id' ) )
  .on( selectCompanyId, ( s, p ) => {
    if ( p ) {
      localStorage.setItem( 'selecte_company_id', p );
    } else {
      localStorage.removeItem( 'selecte_company_id' );
    }
    return p;
  } );

export const updateCompanies = createEvent<ICompany[]>();
export const $companies = createStore<ICompany[]>( [] )
  .on( updateCompanies, ( s, p ) => p );

export const isGeneralCompany = ( companyId?: string ) => {
  return companyId === GENERAL_COMPANY_ID;
};


export const searchCompanies = async ( query: string ) => {
  return await apiGet<ICompany[]>( {
    url: `/admin/companies/search?query=${ query }`
  } );
};

export const listCompanies = async ( filter?: FilterParams ) => {
  return await apiGet<ICompanies>( {
    url: `/admin/companies?${ filter?.toQueryParams() }`
  } );
};

export const getCompany = async ( id?: number | string ) => {
  if ( !id ) return;

  return await apiGet<ICompany>( {
    url: `/admin/companies/${ id }`,
  } );
};

export const postCompany = async ( id: number | string, data: any ) => {
  return await apiPost<ICompany | IError>( {
    url: `/admin/companies/${ id }`,
    postData: data
  } );
};

export const createCompany = async ( data: any ) => {
  return await apiPost<ICompany>( {
    url: '/admin/companies',
    postData: data
  } );
};

export const getPinCode = async ( id?: number | string ) => {
  if ( !id ) return;

  return await apiGet<IPinCode>( {
    url: `/admin/companies/${ id }/pinCodes/last`,
  } );
};

export const refreshPinCode = async ( id?: number | string ) => {
  if ( !id ) return;

  return await apiPost<IPinCode>( {
    url: `/admin/companies/${ id }/pinCodes/refresh`,
    postData: {}
  } );
};
