import React from 'react';
import { ReactComponent as PreloaderSvg } from '../images/preloader.svg';
import { useStore } from 'effector-react';
import { $preloader } from '../stores/preloader';

interface IProps {
  isStore?: boolean;
}

const Preloader = ( { isStore = false }: IProps ) => {
  const active = useStore( $preloader );

  if ( !active && isStore ) return <></>;

  const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
    backdropFilter: 'blur(6px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    // @ts-ignore
    <div className='blur' style={ style }>
      <PreloaderSvg/>
    </div>
  );
};

export default Preloader;
