import React from 'react';
import Access from '../Access/Access';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { objectToSearchParams } from '../../util/search_params';
import { IRoute } from '../../types/routes';
import ChildrenLinks from './ChildrenLinks';

interface IProps {
  route: IRoute;
  index: number;
}

const LinkGroup = ( { index, route }: IProps ) => {
  const children = route.children?.filter( c => !!c.name );

  return (
    <Access permission={ route.access }>
      <Accordion.Item eventKey={ index + route.path }>
        <Accordion.Header className={ 'menu-li ' + (!children?.length ? 'hide-arrow ' : '') }>
          <div className='icon'>
            <route.icon fontSize='small'/>
          </div>
          { route.path && route.component ?
            <Link
              to={ `${ route.path }?${ objectToSearchParams( route.params ) }` }
              className='w-100'
            >{ route.name }</Link>
            :
            route.name
          }
        </Accordion.Header>

        { children &&
          <ChildrenLinks route={ route } childrenRoutes={ children }/>
        }
      </Accordion.Item>
    </Access>
  );
};

export default LinkGroup;
