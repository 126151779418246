import { createEvent, createStore } from 'effector';
import { ISetting } from '../types/settings';
import { apiGet, apiPost } from '../api';
import { useStore } from 'effector-react';
import { useMemo } from 'react';

export const updateSettings = createEvent<ISetting[]>();

export const $settings = createStore<ISetting[]>( [] )
  .on( updateSettings, ( state, newState ) => newState );


export const useSettingByCode = ( code: string ) => {
  const settings = useStore( $settings );
  return useMemo( () => {
    return settings.find( s => s.code === code )?.value;
  }, [ settings.length, code ] );
};

export const getSettings = async () => {
  const response = await apiGet<ISetting[]>( { url: '/admin/settings' } );
  updateSettings( response );

  return response;
};

export const postSettings = async ( postData: FormData ) => {
  const response = await apiPost( {
    url: '/admin/settings',
    postData
  } );
  await getSettings();

  return response;
};

export const getForwardSettings = async () => {
  const response = await apiGet<ISetting[]>( { url: '/forward/settings' } );
  updateSettings( response );

  return response;
};
