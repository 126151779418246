import React from 'react';
import { Navigate } from 'react-router-dom';
import { $admin, $auth, isForwarder } from '../stores/admin';
import { useStore } from 'effector-react';

interface IProps {
  Component: any
}

const AdminRoute = ( { Component }: IProps ) => {
  const auth = useStore( $auth );
  const user = useStore( $admin );

  if ( isForwarder( user ) ) {
    return <Navigate to='/login'/>;
  }

  return auth ? <Component/> : <Navigate to='/login'/>;
};

export default AdminRoute;
