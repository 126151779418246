import React from 'react';
import BootstrapAlert from 'react-bootstrap/Alert';
import './Alert.scss';
import { useStore } from 'effector-react';
import { $alert, hideAlert } from './store';

const Alert = () => {
  const alert = useStore( $alert );

  if ( !alert ) {
    return <></>;
  }

  const close = () => {
    hideAlert();
  };

  setTimeout( () => {
    close();
  }, 6000 );

  return (
    <BootstrapAlert className='custom-alert' variant={ alert.type || 'success' } onClick={ close } onClose={ close }>
      { alert.message }
    </BootstrapAlert>
  );
};

export default Alert;

