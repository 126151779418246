import React, { useEffect, useRef, useState } from 'react';
import './filemanager.scss';
import Item from './Item';
import NavbarFilemanager from './NavbarFilemanager';
import { deleteFiles, listFiles } from './requests';
import { useQuery } from 'react-query';
import { clone } from '../../util/util';
import { IDirectoryItemsResponse, IFileItem, ISelectedItems } from './filemanagerTypes';
import Pagination from '../Pagination/Pagination';

interface IData {
  event?: ( image: string ) => void;
  open?: boolean;
  mime?: string;
  setOpen?: ( open: boolean ) => void;
}

const data: IData = {};
export const openFileManager = ( callback: IData['event'], mime?: string ) => {
  data.event = callback;
  data.mime = 'image/*';
  if ( data.setOpen ) data.setOpen( true );
};

const Filemanager = ( props: any ) => {
  const limit = 20;
  const [ open, setOpen ] = useState( false );
  const [ update, setUpdate ] = useState<number>( Date.now() );
  const [ directory, setDir ] = useState( localStorage.getItem( 'filemanager-directory' ) || '/' );
  const [ page, setPage ] = useState( 1 );

  const form = useRef<any>( null );
  const [ selected, setSelected ] = useState<ISelectedItems>( {} );

  data.open = open;
  data.setOpen = setOpen;

  useEffect( () => {
    setPage( 1 );
  }, [ open, directory, update ] );

  const refreshPage = () => {
    setUpdate( Date.now() );
  };

  const { isLoading, data: response } = useQuery<IDirectoryItemsResponse>(
    [ 'filemanager', open, directory, update ],
    () => listFiles( directory, data.mime ),
    {
      enabled: open
    }
  );

  if ( !open ) return <></>;

  let items: IFileItem[] = [];
  if ( response ) items = [ ...response.directories, ...response.files ];

  const close = () => {
    setOpen( false );
    data.event = undefined;
  };

  const setDirectory = ( value: string ) => {
    setDir( value );
    localStorage.setItem( 'filemanager-directory', value );
  };

  const selectFile = ( item: IFileItem ) => {
    if ( data.event ) data.event( item.path );
    close();
  };

  const selectCheckFile = ( item: IFileItem ) => ( e: any ) => {
    e.stopPropagation();
    let newSelected = clone( selected );
    if ( newSelected[ item.name ] ) {
      delete newSelected[ item.name ];
    } else {
      newSelected[ item.name ] = item.name;
    }
    setSelected( newSelected );
  };

  const deleteItems = async () => {
    const data = new FormData( form.current );
    data.set( 'dir', directory );
    await deleteFiles( data );
    refreshPage();
  };

  return (
    <div className='filemanager-container'>
      <div className='filemanager-overlay'/>
      <div className='filemanager-popup'>
        <button onClick={ close } type='button' className='btn btn-close'></button>
        <div className='filemanager-title h5'>File Manager</div>
        <hr/>
        <NavbarFilemanager
          directory={ directory }
          setDirectory={ setDirectory }
          refreshPage={ refreshPage }
          deleteItems={ deleteItems }
          mime={ data.mime }
        />
        <hr/>
        <form ref={ form } className='filemanager-content mt-20'>
          { items.slice( ( page - 1 ) * limit, page * limit ).map( ( item, i ) =>
            <Item
              key={ item.name }
              item={ item }
              checked={ !!selected[ item.name ] }
              onSelect={ selectFile }
              onCheck={ selectCheckFile }
              setDirectory={ setDirectory }
            />
          ) }
        </form>
        <div className='filemanager-pagination'>
          <Pagination
            page={ page }
            count={ items.length }
            onChange={ setPage }
          />
        </div>
      </div>
    </div>
  );
};

export default Filemanager;
