import { apiDelete, apiGet, apiPost } from '../api';
import { IRole, IRoleByIdResponse, IRoles } from '../types/permissions';

export const SUPER_ADMIN_ROLE_ID = 1;

export const isSuperAdmin = ( roles?: IRole[] ) => {
  if ( !roles ) return false;
  return roles.find( r => r.id === SUPER_ADMIN_ROLE_ID );
};


export const getRoles = async ( page: string | null ) => {
  return await apiGet<IRoles>( {
    url: `/admin/roles?page=${ page }`
  } );
};

export const removeRole = async ( id: number | string ) => {
  if ( confirm( 'Delete this role?' ) ) {
    await apiDelete( {
      url: `/admin/roles/${ id }`
    } );
  }
};

export const getRole = async ( id: number | string ) => {
  return await apiGet<IRoleByIdResponse>( {
    url: `/admin/roles/${ id }`
  } );
};

export const updateRole = async ( id: number | string, data: any ) => {
  return await apiPost<IRole>( {
    url: `/admin/roles/${ id }`,
    postData: data
  } );
};

export const createRole = async ( data: any ) => {
  return await apiPost<IRole>( {
    url: '/admin/roles',
    postData: data
  } );
};
