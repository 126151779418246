import React from 'react';
// import ReactPaginate from 'react-paginate';
// import './pagination.scss';
import { Pagination as MuiPagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  className?: string;
  page?: number;
  count?: number;
  onChange?: ( page: number ) => void;
}

const Pagination = ( { className, page, count = 0, onChange }: IProps ) => {
  const limit = 20;
  const [ searchParams, setSearchParams ] = useSearchParams();
  const _page = page || parseInt( searchParams.get( 'page' ) || '1' );

  const pagesTotal = Math.ceil( count / limit );

  return (
    <MuiPagination
      className={ className }
      key={ count }
      page={ _page }
      count={ pagesTotal }
      onChange={ ( e, p ) => {
        if ( onChange ) {
          onChange( p );
        } else {
          //@ts-ignore
          searchParams.set( 'page', p );
          setSearchParams( searchParams );
        }
      } }
    />
  );
};

export default Pagination;
