export function clone<T>( el: T ): T {
  if ( !el ) return el;
  return JSON.parse( JSON.stringify( el ) );
}

export const numberFormat = ( number: any, decimals?: any, dec_point?: string, thousands_sep?: string ) => {

  let i, j, kw, kd, km;

  // input sanitation & defaults
  if ( isNaN( decimals = Math.abs( decimals ) ) ) {
    decimals = 0;
  }

  if ( dec_point === undefined ) {
    dec_point = '.';
  }
  if ( thousands_sep === undefined ) {
    thousands_sep = ' ';
  }

  i = parseInt( number = ( +number || 0 ).toFixed( decimals ) ) + '';

  if ( ( j = i.length ) > 3 ) {
    j = j % 3;
  } else {
    j = 0;
  }

  km = ( j ? i.substr( 0, j ) + thousands_sep : '' );
  kw = i.substr( j ).replace( /(\d{3})(?=\d)/g, '$1' + thousands_sep );
  // @ts-ignore
  kd = ( decimals ? dec_point + Math.abs( number - i ).toFixed( decimals ).replace( /-/, '0' ).slice( 2 ) : '' );

  return km + kw + kd;
};

export function plural( n: any ) {
  // @ts-ignore
  let args = [ ...arguments ];
  args.shift();
  let idx;
  if ( n % 10 === 1 && n % 100 !== 11 ) {
    idx = 0; // many
  } else if ( n % 10 >= 2 && n % 10 <= 4 && ( n % 100 < 10 || n % 100 >= 20 ) ) {
    idx = 1; // few
  } else {
    idx = 2; // one
  }
  return args[ idx ] || args[ idx - 1 ] || args[ idx - 2 ];
}

export const checkIOS = () => {
  let isIOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes( navigator.platform )
    // iPad on iOS 13 detection
    || ( navigator.userAgent.includes( 'Mac' ) && 'ontouchend' in document );


  //@ts-ignore
  let isIOS2 = /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream;

  return isIOS || isIOS2;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test( navigator.userAgent );

export const isObject = ( el: any ) => {
  return typeof el === 'object';
};

export const scrollToTarget = ( el: any, offset: number ) => {
  let elementPosition = el.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - offset;

  window.scrollTo( {
    top: offsetPosition,
    behavior: 'smooth'
  } );
};


export const inArray = ( search: any, array: any[] ) => {
  for ( let i = 0; i < array.length; i++ ) {
    if ( array[ i ] === search ) return true;
  }
  return false;
};

export function newArray( length: number, value?: any ): any[] {
  return Array.apply( value, Array( length ) );
}

export const delay = function () {
  let timer: any;

  return function ( callback: any, ms: number ) {
    if ( timer ) clearTimeout( timer );
    timer = setTimeout( callback, ms );
  };
};

export function cn( ...args: any[] ): string {
  return args.map( ( arg ) => arg || '' ).join( ' ' );
}
