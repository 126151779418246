import { createEvent, createStore } from 'effector';
import { apiGet } from '../api';
import { ICity } from '../types/cities';

export const updateCities = createEvent<ICity[]>();

export const $cities = createStore<ICity[]>( [] )
  .on( updateCities, ( state, payload ) => payload );

export const getCities = async () => {
  const response = await apiGet<ICity[]>( {
    url: '/admin/geo/cities'
  } );

  updateCities( response );

  return response;
};
