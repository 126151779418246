import React from 'react';
import Access from '../Access/Access';
import { Link } from 'react-router-dom';
import { objectToSearchParams } from '../../util/search_params';
import { Accordion } from 'react-bootstrap';
import { IRoute } from '../../types/routes';

interface IProps {
  route: IRoute;
  childrenRoutes: IRoute[];
}

const ChildrenLinks = ( { route, childrenRoutes }: IProps ) => {
  return (
    <Accordion.Body>
      { childrenRoutes.map( ( child, index ) => {
        return (
          <Access key={ index } permission={ child.access }>
            <Link
              to={ `${ route.path }/${ child.path }?${ objectToSearchParams( child.params ) }` }
            >{ child.name }</Link>
          </Access>
        );
      } ) }
    </Accordion.Body>
  );
};

export default ChildrenLinks;
