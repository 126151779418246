import React from 'react';
import './hamburger.scss';

interface IProps {
  open: boolean;
  onClick: () => void;
}

const Hamburger = ( { open, onClick }: IProps ) => {
  return (
    <button type='button' className={ open ? 'hamburger active' : 'hamburger' } onClick={ onClick }>
      <div className='hamburger-line top'/>
      <div className='hamburger-line center'/>
      <div className='hamburger-line bottom'/>
    </button>
  );
};

export default Hamburger;
