export const objectToSearchParams = ( object?: any ) => {
  if ( !object ) return '';
  let temp: any[] = [];
  Object.keys( object ).forEach( ( key ) => {
    if ( typeof object[ key ] === 'function' ) {
      return;
    }

    if ( object[ key ] instanceof Array ) {
      temp.push( arrayToSearchParams( key, object[ key ] ) );
    } else if ( typeof object[ key ] === 'object' ) {
      temp.push( objectToSearchParams( object[ key ] ) );
    } else {
      temp.push( `${ key }=${ object[ key ] }` );
    }
  } );

  return temp.join( '&' );
};

export const arrayToSearchParams = ( key: number | string, array: any[] ) => {
  let temp: any[] = [];
  array.forEach( ( value ) => {
    if ( value instanceof Array ) {
      temp.push( arrayToSearchParams( `${ key }[]`, value ) );
    } else if ( typeof value === 'object' ) {
      temp.push( objectToSearchParams( value ) );
    } else {
      temp.push( `${ key }[]=${ value }` );
    }
  } );

  return temp.join( '&' );
};
