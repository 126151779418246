import {
  ICreateFolderRequest,
  IDeleteItemsRequest,
  IDirectoryItemsResponse,
  IUploadFileRequest
} from './filemanagerTypes';
import { apiGet, apiPost } from '../../api';

export const listFiles = async ( directory: string, mime?: string ) => {
  return await apiGet<IDirectoryItemsResponse>( { url: `/admin/filemanager/directory?dir=${ directory }&mime=${ mime || '' }` } );
};

export const deleteFiles = async ( data: IDeleteItemsRequest | FormData ) => {
  return await apiPost( {
    url: '/admin/filemanager/delete',
    postData: data,
  } );
};

export const uploadFile = async ( data: IUploadFileRequest | FormData ) => {
  return await apiPost( {
    url: '/admin/filemanager/upload',
    postData: data,
  } );
};

export const createFolder = async ( data: ICreateFolderRequest | FormData ) => {
  return await apiPost( {
    url: '/admin/filemanager/create',
    postData: data,
  } );
};
