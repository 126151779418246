import { BrowserRouter, Route } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import './util/date';
import './index.scss';
import './components/Table/table.scss';
import { $admin, $auth, getAdmin, isAdmin, isForwarder, updateAdmin } from './stores/admin';
import LeftColumn from './components/left_column/LeftColumn';
import Filemanager from './components/filemanager/Filemanager';
import { getForwardSettings, getSettings } from './stores/settings';
import { useStore } from 'effector-react';
import Preloader from './components/Preloader';
import { Routes } from 'react-router';
import ScrollToTop from './components/ScrollToTop';
import Alert from './components/Alert/Alert';
import { routes } from './stores/routes';
import { listFridges } from './stores/fridges';
import { getCities } from './stores/cities';
import { listUsers } from './stores/user';
import { yandexMetrics } from './util/yandex';
import { $userPermissions, getPermissions } from './stores/permissions';
import { $selectedCompanyId, listCompanies, updateCompanies } from './stores/companies';
import { isSuperAdmin } from './stores/roles';

function App() {
  const auth = useStore( $auth );
  const user = useStore( $admin );
  const admin = isAdmin( user );
  const permissions = useStore( $userPermissions );
  const selectedCompanyId = useStore( $selectedCompanyId );

  useEffect( () => {
    if ( auth ) getAdmin().then( updateAdmin );
  }, [ auth ] );
  
  useEffect( () => {
    if ( admin ) {
      listFridges().then();
      getCities().then();
      listUsers().then();

      if ( user?.roles && isSuperAdmin( user.roles ) ) {
        listCompanies().then( r => updateCompanies( r.items ) );
      }

      if ( permissions?.get( 'settings', 'list' ) ) {
        getSettings().then();
      }
      if ( permissions?.get( 'permissions', 'list' ) ) {
        getPermissions().then();
      }
    }

    if ( isForwarder( user ) ) {
      getForwardSettings().then();
      yandexMetrics();
    }
  }, [ user?.id ] );

  if ( auth && !user ) return <Preloader/>;

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <div className={ 'page d-flex flex-wrap ' + ( admin ? 'app-admin' : '' ) }>
        <Alert/>
        <Preloader isStore={ true }/>
        { admin &&
          <>
            <Filemanager/>
            <LeftColumn/>
          </>
        }

        <Suspense fallback={ <Preloader/> }>
          <Routes key={ selectedCompanyId }>
            { routes.map( route => {
              return (
                <React.Fragment key={ route.path }>
                  { route.path && route.component &&
                    <Route path={ route.path } element={
                      route.protection ? <route.protection Component={ route.component }/> : <route.component/>
                    }/>
                  }
                  { route.children?.map( ( child, index ) => {
                    const Protection = child.protection || route.protection;
                    return <Route key={ index } path={ `${ route.path }/${ child.path }` } element={
                      Protection ? <Protection Component={ child.component }/> :
                        <child.component/>
                    }/>;
                  } ) }
                </React.Fragment>
              );
            } ) }
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
