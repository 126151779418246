import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient( {
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      retry: 0,
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
    },
  },
} );

// @ts-ignore
const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
// eslint-disable-next-line no-undef
const Mode = process.env.NODE_ENV === 'development' ? React.Fragment : React.StrictMode;
root.render(
  <Mode>
    <QueryClientProvider client={ queryClient }>
      <App/>
    </QueryClientProvider>
  </Mode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
