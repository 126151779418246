import { createEvent, createStore } from 'effector';
import { IAdmin, ILoginResponse, IOwnerAuth } from '../types/admin';
import { apiGet, apiPost } from '../api';
import { showPreloader } from './preloader';
import { IUser } from '../types/users';
import { getUserToken } from './user';

export const getAdminToken = () => {
  return localStorage.getItem( 'admin_token' ) || '';
};

export const USER_ADMIN_TYPE = 0;
export const USER_FORWARD_TYPE = 1;

const credentials = {
  token: getAdminToken()
};

export const updateCredentials = ( token: string ) => {
  if ( token ) {
    localStorage.setItem( 'admin_token', token );
    credentials.token = token;
    login();
    getAdmin().then( updateAdmin );
  }
};

const clearCredentials = () => {
  credentials.token = '';
  localStorage.removeItem( 'admin_token' );
  clearAdmin();
};

export const authInUser = async ( user: IUser, owner?: IAdmin | null ) => {
  const response = await getUserToken( user.id );
  if ( response.token ) {
    setOwnerUser( owner );
    updateCredentials( response.token );
    window.location.reload();
  }
};

export const logoutUser = ( ownerAuth: IOwnerAuth ) => ( e: any ) => {
  e.preventDefault();
  if ( ownerAuth.token ) {
    clearOwnerUser();
    updateCredentials( ownerAuth.token );
    window.location.reload();
  } else {
    logoutAdmin();
  }
};

export const setOwnerUser = ( owner?: IAdmin | null ) => {
  localStorage.setItem( 'owner_auth', `${ owner?.fullname || owner?.username }|${ credentials.token }` );
};

export const getOwnerUser = (): IOwnerAuth => {
  let data: any = localStorage.getItem( 'owner_auth' );
  if ( data ) {
    data = data.split( '|' );
    return {
      token: data[ 1 ],
      name: data[ 0 ]
    };
  }
  return {};
};

export const clearOwnerUser = () => {
  localStorage.removeItem( 'owner_auth' );
};

export const login = createEvent();
export const logoutAdmin = createEvent();

export const $auth = createStore<boolean>( Boolean( credentials.token ) )
  .on( login, () => {
    return true;
  } )
  .on( logoutAdmin, () => {
    clearCredentials();
    return false;
  } );


export const updateAdmin = createEvent<IAdmin>();
export const clearAdmin = createEvent();

export const $admin = createStore<IAdmin | null>( null )
  .on( updateAdmin, ( state, payload ) => payload )
  .reset( clearAdmin );

export const isAdmin = ( user: IAdmin | null ) => {
  return user?.typeId === USER_ADMIN_TYPE;
};

export const isForwarder = ( user: IAdmin | null ) => {
  return user?.typeId === USER_FORWARD_TYPE;
};

export const auth = async ( data: any ) => {
  const response = await apiPost<ILoginResponse>( {
    url: '/admin/auth',
    postData: data
  } );

  if ( 'token' in response ) {
    updateCredentials( response.token );
  }

  return response;
};

export const getAdmin = async () => {
  showPreloader();
  return await apiGet<IAdmin>( {
    url: '/admin/user',
  } );
};

export const listUser = async () => {
  return await apiGet( {
    url: '/admin/users'
  } );
};

export const updateUser = async ( userId: string, postData: any ) => {
  return await apiPost( {
    url: '/admin/users/' + userId,
    postData
  } );
};
