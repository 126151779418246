import React, { useRef } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UploadFile = ( props: any ) => {
  const input = useRef( null );
  const form = useRef<any>( null );

  const uploadFile = () => {
    const data = new FormData( form.current );
    props.onSelect( data );
    // @ts-ignore
    input.current.value = null;
  };

  return (
    <form ref={ form } onSubmit={ uploadFile } style={ { display: 'inline' } }>
      <input
        ref={ input }
        onChange={ uploadFile }
        accept={ props.mime }
        type='file'
        name='files[]'
        multiple={ true }
        style={ { opacity: 0, width: 0, height: 0, padding: 0, margin: 0 } }
      />
      <button type='button' className='btn' onClick={ () => {
        // @ts-ignore
        input.current.click();
      } }><FileUploadIcon/></button>
    </form>
  );
};

export default UploadFile;
