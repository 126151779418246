import React, { useState } from 'react';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFile from './UploadFile';
import { createFolder, uploadFile } from './requests';

interface IProps {
  directory: string;
  setDirectory: ( dir: string ) => void;
  refreshPage: () => void;
  deleteItems: () => void;
  mime?: string;
}

const NavbarFilemanager = ( { directory, setDirectory, refreshPage, deleteItems, mime }: IProps ) => {
  const [ openCreateFolder, setOpenCreateFolder ] = useState( false );

  let dirs = [ {
    'name': 'root',
    'path': ''
  } ];

  directory.split( '/' ).map( ( d, i ) => {
    if ( d ) {
      dirs.push( {
        'name': d,
        'path': directory.split( '/' ).filter( ( f, ind ) => ind <= i ).join( '/' )
      } );
    }
  } );

  const toParentFolder = () => {
    let d = directory.split( '/' );
    let l = d.length;
    let newDirectory = d.filter( ( d, i ) => i !== ( l - 1 ) ).join( '/' );

    setDirectory( newDirectory );
  };

  const uploadFileFunc = async ( data: any ) => {
    data.set( 'dir', directory );
    await uploadFile( data );
    refreshPage();
  };

  const createFolderFunc = async ( e: any ) => {
    e.preventDefault();
    const data = new FormData( e.target );
    data.set( 'dir', directory );

    await createFolder( data );
    setOpenCreateFolder( false );
    refreshPage();
  };

  return (
    <div className='filemanager-navbar'>
      <div>directory: { dirs.map( ( d, i ) =>
        <span key={ d.path } onClick={ () => {
          setDirectory( d.path );
        } }>{ d.name }/</span>
      ) }</div>
      <div className='button-group mb-10'>
        <button type='button' className='btn' onClick={ toParentFolder }><TurnLeftIcon/></button>
        <button type='button' className='btn' onClick={ refreshPage }><AutorenewIcon/></button>

        <UploadFile mime={ mime } onSelect={ uploadFileFunc }/>

        <button type='button' className='btn' onClick={ () => {
          setOpenCreateFolder( true );
        } }><CreateNewFolderIcon/></button>
        <button type='button' className='btn' onClick={ deleteItems }><DeleteIcon/></button>
      </div>
      { openCreateFolder ?
        <form onSubmit={ createFolderFunc } className='create-folder'>
          <div className='h5'>Folder name</div>
          <input type='text' name='name' className='form-control'/>
          <button type='submit' className='btn btn-dark'>ok</button>
        </form>
        : '' }
    </div>
  );
};

export default NavbarFilemanager;
