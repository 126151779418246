import 'moment/locale/ru';
import moment from 'moment/moment';
import moment2 from 'moment';

moment.locale( 'ru' );
moment2.locale( 'ru' );

export const getWeeks = ( date: moment.Moment ) => {
  const next = false;
  date = date.clone();

  const week = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Cб', 'Вс' ];
  let result = [];

  if ( !next ) {
    date = date.startOf( 'week' );
  }

  for ( let i = 0; i < week.length; i++ ) {
    let _date = date.clone();
    _date.add( i, 'd' );
    result.push( _date );
  }

  return result;
};

function pad( number: number ) {
  if ( number < 10 ) {
    return '0' + number;
  }
  return number;
}

export function parseDate( isoString?: any ): moment.Moment {
  moment.locale( 'ru' );
  if ( typeof isoString === 'object' && isoString._isAMomentObject ) return isoString;
  if ( !isoString ) return moment();
  if ( typeof isoString === 'number' ) return moment.unix( isoString );
  return moment( isoString );
}


export const getTimeFromMins = ( mins: number ) => {
  let hours = Math.trunc( mins / 60 );
  let minutes = mins % 60;
  return [ hours, minutes ];
};

export const parseTime = ( string: string ) => {
  if ( !string ) return 0;
  let time = string.split( ':' );
  // @ts-ignore
  return ( time[ 0 ] * 60 ) + parseInt( time[ 1 ] );
};

export const formatToHour = ( date: Date ) => {
  let h: any = date.getHours();
  let m: any = date.getMinutes();
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h + ':' + m;
};
