import React from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {IFileItem} from './filemanagerTypes';
import {imageResource} from '../../util/image';

interface IProps {
  item: IFileItem;
  checked?: boolean;
  setDirectory: (path: string) => void;
  onSelect: (item: IFileItem) => void;
  onCheck: (item: IFileItem) => (e: any) => void;
}

const Item = ({item, checked, setDirectory, onSelect, onCheck}: IProps) => {
  const imageExts = ['gif', 'jpg', 'png', 'svg', 'jpeg'];
  let n = item.name.split('.');
  const ext = (n.length > 1 ? n[n.length - 1] : '')

  let view = <FolderIcon className='item'/>;

  if (imageExts.indexOf(ext) !== -1) {
    view = <img src={imageResource(item.path, 100, 100)} alt='' className='item'/>;
  } else if (item.type === 'file') {
    view = <FileCopyIcon className='item'/>;
  }

  const click = (e: any) => {
    if (item.type === 'directory') {
      setDirectory(item.path);
    } else {
      onSelect(item);
    }
  }

  return (
    <div onClick={click} className={'filemanager-item ' + item.type} title={item.name}>
      {view}
      <span className='name'>{item.name}</span>
      <input
        type='checkbox'
        className='item-checkbox pointer'
        onClick={onCheck(item)}
        onChange={() => {
        }}
        name='items[]'
        value={item.name}
        checked={checked}
      />
    </div>
  );
}

export default Item;
