import React, { useMemo, useState } from 'react';
// @ts-ignore
import logo from '../../images/logo.svg';
import './aside.scss';
import { Accordion } from 'react-bootstrap';
import Hamburger from '../hamburger/Hamburger';
import { useStore } from 'effector-react';
import { $admin, $auth, getOwnerUser, logoutUser } from '../../stores/admin';
import { routes } from '../../stores/routes';
import LinkGroup from './LinkGroup';
import { $companies, $selectedCompanyId, selectCompanyId } from '../../stores/companies';
import CustomSelect from '../Select/CustomSelect';
import { ISelectOption } from '../../types/util';

const LeftColumn = () => {
  const isAuth = useStore( $auth );
  const admin = useStore( $admin );
  const [ open, setOpen ] = useState( false );
  const companies = useStore( $companies );
  const selectedCompanyId = useStore( $selectedCompanyId );

  const ownerAuth = useMemo( () => {
    return getOwnerUser();
  }, [ admin?.id ] );

  let menu = routes;

  if ( !isAuth ) menu = [];

  const options: ISelectOption[] = [ { value: '', label: 'Все компании' } ];
  companies.forEach( c => options.push( { value: c.id, label: c.name } ) );

  const onSelectCompany = ( value: any ) => {
    selectCompanyId( value.value );
  };

  return (
    <aside className={ open ? 'aside open' : 'aside ' }>
      <Hamburger open={ open } onClick={ () => setOpen( !open ) }/>
      <div className='logo'>
        <img src={ logo } alt=''/>
      </div>

      { Boolean( isAuth ) &&
        <>
          { ownerAuth.token &&
            <div className="auth-user">
              <div>{ ownerAuth.name } { '->' }</div>
            </div>
          }
          <div className="auth-user">
            <div>{ admin?.fullname || admin?.username }&nbsp;&nbsp;&nbsp;<a href="#"
                                                                            onClick={ logoutUser( ownerAuth ) }>Выйти</a>
            </div>
          </div>
        </>
      }

      { companies.length > 1 &&
        <div className="mb-20 companies-select">
          <CustomSelect
            defaultValue={ options.find( o => o.value === selectedCompanyId ) || options[ 0 ] }
            options={ options }
            onChange={ onSelectCompany }
          />
        </div>
      }

      <Accordion defaultActiveKey='1' className='menu'>
        { menu.filter( m => !!m.name ).map( ( route, index ) =>
          <LinkGroup key={ index } index={ index } route={ route }/>
        ) }
      </Accordion>
    </aside>
  );
};

export default LeftColumn;
